import "styles/pages/page-text.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import PageTile from "components/PageTile";
import Breadcrumps from "components/Breadcrumps";

const TilesPage = ({ pageContext }) => {
  const pageData = pageContext.data.tilesPage;
  const seo = pageContext.seo;

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.title} />
      <SubpageHeader smallMargin title={pageContext.title} />
      <section className="tiles-page" style={{ paddingTop: 45 }}>
        <div className="container">
          <div className="row">
            {pageData.tiles?.map((item, index) => (
              <div className="col-md-4 col-sm-6" key={index}>
                <PageTile
                  title={item.title}
                  color={item.color}
                  url={item.page?.uri}
                  thumbnail={item.img?.sourceUrl}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TilesPage;
